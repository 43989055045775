import DOMpurify, { Config } from 'isomorphic-dompurify'
import marked from 'marked'

type Markdown = (
  data: string | null,
  options?: MarkdownOptions
) => string

export type MarkdownOptions = {
  sanitize?: boolean
  sanitizeConfig?: Config
}

// Setting breaks to true will allow the parser to add a <br /> element on a
// single line break. This retains the same functionality as our current Rails
// markdown parser, as much of our markdown fields in Contentful have been
// written under this assumption.
export const markdown: Markdown = (data, options) => {
  const sanitize = (source: string) => options?.sanitize
    ? DOMpurify.sanitize(source, options?.sanitizeConfig) as string
    : source

  return data && sanitize(marked(data, { breaks: true }))
}

export const interpolateContent = (
  data: string | null, values: Record<string, string>,
) => {
  Object.entries(values).forEach(([key, value]) => {
    data = data.replace(new RegExp(`#{${key}}`, 'g'), value)
  })
  return data
}
