import { PrimaryNotificationType } from '@viewlio/types/src/contentful'

import { markdown } from 'utils/markdown'

export const getProps = ({ content }: PrimaryNotificationType) => ({
  content: markdown(content),
  cta: {
    text: null,
    url: null,
  },
  modal: null,
  notificationType: 'shipping',
})
