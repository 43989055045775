import React, { FC } from 'react'

import { PrimaryNotificationType } from '@viewlio/types/src/contentful'

import { PrimaryNotification as BasePrimaryNotification } from 'components/common/PrimaryNotification'

import { getProps } from './PrimaryNotification.serializer'

export const PrimaryNotification: FC<PrimaryNotificationType> = props => {
  const { content } = getProps(props)

  return (
    <>
      <BasePrimaryNotification
        message={content}
        allowDangerousHTML={true}
        dismissible={false}
        millisecondsUntilFade={0}
        style='cart'
      />
    </>
  )
}
